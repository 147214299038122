/* PLACE YOU CUSTOM STYLES IN THIS FILE */

.text-align-left{
    text-align: left;
}
.text-align-center{
    text-align: center;
}
.text-align-right{
    text-align: right;
}

.dk-portfolio-frontpage-title {
    margin-left:620px;
    margin-top:500px;
    white-space: nowrap;
}

@media screen and (max-width: 880px) {
	.dk-portfolio-frontpage-title {
		margin-left: 0;
    	margin-top:0;
    }
}